<template>
	<section class="grid__form">
		<div class="grid__form__header">
			<div class="nav__logo">
				<a href="">
					Company Logo
				</a>
			</div>
		</div>
		<div class="container">
			<div class="form__wrapper">
				<template v-if="!passwordReset">
					<div class="form__title">
						Forgot your password?
					</div>
					<div class="form__body">
						<form method="post" novalidate>
							<div class="form-row">
								<div class="col-md-12">
									<div class="form-group">
										<label class="form__input__label" for="email">Email Address</label>
										<input
											id="email"
											:class="[{ 'is-invalid': errors.email }]"
											@keydown="clearErrors($event.target.name)"
											v-model="itemData.email"
											class="form-control form__input--lg"
											type="email"
											name="email"
											value=""
											placeholder="Email Address"
											required
										/>
										<span class="form__icon">
											<svg width="20" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 
													2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 
													5-8-5V2l8 5 8-5v2z"
													fill="#828282"
												/>
											</svg>
										</span>
										<div class="invalid-feedback">
											{{ errors.email }}
										</div>
									</div>
								</div>
							</div>
							<div class="form__group">
								<button v-bind="{ disabled: loading }" class="btn btn__primary btn__primary--lg">
									Reset Password
								</button>
							</div>
						</form>
					</div>
				</template>
				<template v-else>
					<div class="form__title">
						<p>Your password has been reset and an email sent to you. Thanks.</p>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'ForgotPassword',
	data() {
		return {
			passwordReset: false,
			itemData: {},
			errors: {},
			loading: true
		};
	}
};
</script>
